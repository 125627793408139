var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    !_vm.$apollo.queries.booking.loading
      ? _c("div", [
          _c("table", { staticClass: "header" }, [
            _c("thead", [
              _c("tr", [
                _c("td", { staticClass: "logo" }, [
                  _c("img", {
                    attrs: {
                      src: require("@public/img/brand/logo-print.svg"),
                      width: "170",
                      height: "41",
                    },
                  }),
                ]),
                _c("td", { staticClass: "about" }, [
                  _c("h1", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$i18n.t("contract.titles.contractOfAdhesion")
                        ) +
                        " "
                    ),
                  ]),
                  _c("h2", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$i18n.t("contract.titles.koviCnpj")) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", { staticClass: "contract" }, [
                  _c("h2", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$i18n.t("contract.words.contract")) + ":"
                      ),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$i18n.t("contract.texts.contractCode", {
                            code: _vm
                              .$moment(_vm.booking.created_at)
                              .format("X"),
                          })
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("table", { staticClass: "info" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "driver" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$i18n.t("contract.words.client")) + " "
                  ),
                ]),
                _c("th", { staticClass: "car" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$i18n.t("contract.words.vehicle")) + " "
                  ),
                ]),
                _c("th", { staticClass: "booking" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$i18n.t("contract.words.location")) + " "
                  ),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "driver" }, [
                  _c("p", { staticStyle: { width: "100%" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$i18n.t("contract.words.name")) + ":"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.booking.driver.name) + " "),
                  ]),
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$i18n.t("contract.words.license_number")) +
                          ":"
                      ),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.setNumberFormat(_vm.booking.driver.license_number)
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$i18n.t("contract.words.document")) + ":"
                      ),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.setCpfFormat(_vm.booking.driver.cpf)) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$i18n.t("contract.words.phone")) + ":"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.setPhoneFormat(_vm.booking.driver.phone_number)
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", { staticStyle: { width: "100%" } }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$i18n.t("contract.texts.contractedPlan")) +
                          ":"
                      ),
                    ]),
                    _vm._v(" " + _vm._s(_vm.booking.plan.name) + " "),
                  ]),
                ]),
                _c("td", { staticClass: "car" }, [
                  _c("p", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$i18n.t("contract.words.plate")) + ":"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.booking.car.license_plate) + " "),
                  ]),
                  _c("p", { staticStyle: { width: "100%" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$i18n.t("contract.words.model")) + ":"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.booking.car.brand.name) +
                        " " +
                        _vm._s(_vm.booking.car.version) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$i18n.t("contract.words.renavam")) + ":"
                      ),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.setNumberFormat(_vm.booking.car.license_number)
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$i18n.t("contract.words.fuel")) + ":"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.setFuelFormat(_vm.booking.car.fuel, "/")) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$i18n.t("contract.texts.currentKm")) + ":"
                      ),
                    ]),
                    _vm._v("  "),
                    _vm.booking.odometer > 0
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.setNumberFormat(_vm.booking.odometer)
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [_vm._v(" [                   ] ")]),
                    _vm._v(
                      " " + _vm._s(_vm.$i18n.t("contract.words.km")) + " "
                    ),
                  ]),
                ]),
                _c("td", { staticClass: "booking" }, [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$i18n.t("contract.texts.startDate", {
                          date: _vm
                            .$moment(_vm.booking.started_at)
                            .format("DD/MM/YYYY"),
                        })
                      ),
                    },
                  }),
                  _c("p", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$i18n.t("contract.texts.endDate")
                        ),
                      },
                    }),
                    _vm.booking.finished_at
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  _vm.booking.finished_at,
                                  "DD/MM/YYYY"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  this.getFinishedDate(
                                    _vm.booking.started_at,
                                    this.countryIsBrazil ? 60 : 18
                                  ),
                                  "DD/MM/YYYY"
                                )
                              ) +
                              " "
                          ),
                        ]),
                  ]),
                  _vm.booking.plan.product_type ===
                  _vm.product_types.KOVI_PROPRIO_2
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$i18n.t(
                                "contract.texts.purchaseOptionPeriod",
                                {
                                  purchase_option_period: _vm.setNumberFormat(
                                    _vm.booking.plan.purchase_option_period
                                  ),
                                }
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$i18n.t(
                          "contract.texts.phraseLocationWillBeExtended"
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("table", { staticClass: "legal" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "general" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$i18n.t("contract.texts.generalProvision")) +
                      " "
                  ),
                ]),
              ]),
            ]),
            _c("tbody", [
              _vm.$i18n.i18next.t("contract.texts.phraseClauseOne")
                ? _c("tr", [
                    _c("td", [
                      _vm.$i18n.i18next.exists("contract.texts.clauseOne")
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("contract.texts.clauseOne"))
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$i18n.t("contract.texts.phraseClauseOne")
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.$i18n.i18next.t("contract.texts.phraseClauseTwo")
                ? _c("tr", [
                    _c("td", [
                      _vm.$i18n.i18next.exists("contract.texts.clauseTwo")
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("contract.texts.clauseTwo"))
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$i18n.t("contract.texts.phraseClauseTwo")
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.$i18n.i18next.t("contract.texts.phraseClauseThree")
                ? _c("tr", [
                    _c("td", [
                      _vm.$i18n.i18next.exists("contract.texts.clauseThree")
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("contract.texts.clauseThree"))
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$i18n.t("contract.texts.phraseClauseThree", {
                              license_plate: _vm.booking.car.license_plate,
                              company_name:
                                _vm.booking.car.order.contract.company_name,
                              company_vat: _vm.setVatFormat(
                                _vm.booking.car.order.contract.company_vat
                              ),
                            })
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.$i18n.i18next.t("contract.texts.phraseClauseFour") &&
              !_vm.countryIsBrazil
                ? _c("tr", [
                    _c("td", [
                      _vm.$i18n.i18next.exists("contract.texts.clauseFour")
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("contract.texts.clauseFour"))
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$i18n.t("contract.texts.phraseClauseFour")
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
              !_vm.countryIsBrazil
                ? _c("tr", [
                    _c("td", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$i18n.t("contract.texts.otherClauses")
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("table", { staticClass: "comercial-agreements" }, [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "comercial", attrs: { colspan: "2" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$i18n.t("contract.texts.commercialConditions")
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "description" }, [
                  _c("h1", [
                    _vm._v(_vm._s(_vm.$i18n.t("contract.words.rental"))),
                  ]),
                  _c("table", [
                    _c("tr", [
                      _c("th", { staticClass: "label" }, [_vm._v("   ")]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$i18n.t("contract.words.valueUnit"))),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$i18n.t("contract.words.descPercent"))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$i18n.t("contract.words.descCurrency"))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$i18n.t("contract.words.quantity"))),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$i18n.t("contract.words.totalValue"))
                        ),
                      ]),
                      _vm.isPixInstallments
                        ? _c("th", [_vm._v(_vm._s("Parcelas"))])
                        : _vm._e(),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$i18n.t("contract.words.payment"))),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$i18n.t("contract.words.bond"))),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.getPrePayAmountFormatted(
                              _vm.getPrePaymentKind()
                            )
                          )
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.setDiscountFormat(
                              _vm.booking.pre_transaction_discount
                            )
                          )
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.setPriceFormat(
                                _vm.getPrePayAmount(_vm.getPrePaymentKind()) *
                                  _vm.booking.pre_transaction_discount
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", [_vm._v("1")]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.setPriceFormat(
                                _vm.getPrePayAmount(_vm.getPrePaymentKind()) -
                                  _vm.getPrePayAmount(_vm.getPrePaymentKind()) *
                                    _vm.booking.pre_transaction_discount
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm.isPixInstallments
                        ? _c("td", [
                            _vm._v(
                              _vm._s(_vm.booking.pre_transaction_installments)
                            ),
                          ])
                        : _vm._e(),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.setPaymentType(_vm.booking.pre_transaction_type)
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$i18n.t("contract.words.rental"))),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.setKindAmount(
                              _vm.transactionKindEnum.RECURRENCY
                            )
                          )
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.setDiscountFormat(
                              _vm.booking.transaction_discount
                            )
                          )
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.setPriceFormat(
                              _vm.getKindAmount(
                                _vm.transactionKindEnum.RECURRENCY
                              ) * _vm.booking.transaction_discount
                            )
                          )
                        ),
                      ]),
                      _c("td", [_vm._v("1")]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.setPriceFormat(
                                _vm.getKindAmount(
                                  _vm.transactionKindEnum.RECURRENCY
                                ) -
                                  _vm.getKindAmount(
                                    _vm.transactionKindEnum.RECURRENCY
                                  ) *
                                    _vm.booking.transaction_discount
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm.isPixInstallments
                        ? _c("td", [_vm._v(" - ")])
                        : _vm._e(),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.setPaymentType(_vm.booking.transaction_type)
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.$i18n.t(
                              "contract.words.subscriptionTerminationFine"
                            )
                          )
                        ),
                      ]),
                      _c("td", [_vm._v(" - ")]),
                      _c("td", [_vm._v(" - ")]),
                      _c("td", [_vm._v(" - ")]),
                      _c("td", [_vm._v(" - ")]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.setKindAmount(
                                _vm.transactionKindEnum.SUBSCRIPTION_TERMINATION
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", [_vm._v(" - ")]),
                      _c("td", [_vm._v(" - ")]),
                    ]),
                  ]),
                ]),
                _c("td", { staticClass: "protection" }, [
                  _c("h1", [
                    _vm._v(
                      _vm._s(_vm.$i18n.t("contract.texts.protectionPackage"))
                    ),
                  ]),
                  _vm.countryIsBrazil
                    ? _c("table", { staticClass: "protection-brazil" }, [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                width: "70%",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$i18n.t(
                                    "contract.texts.coParticipationAccident"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td", [
                            _c(
                              "span",
                              { staticClass: "protection-brazil-price" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.t(
                                      "contract.texts.coParticipationAccidentPrice"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                width: "70%",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$i18n.t(
                                    "contract.texts.coParticipationTheft"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td", [
                            _c(
                              "span",
                              { staticClass: "protection-brazil-price" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.t(
                                      "contract.texts.coParticipationTheftPrice"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                width: "70%",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$i18n.t(
                                    "contract.texts.coParticipationTheftInOtherCases"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td", [
                            _c(
                              "span",
                              { staticClass: "protection-brazil-price" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.t(
                                      "contract.texts.coParticipationTheftInOtherCasesPrice"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticStyle: { "text-align": "left" },
                              attrs: { colspan: "2" },
                            },
                            [
                              _c("span", { staticClass: "general" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.t("contract.texts.phraseOneTerm")
                                  )
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "general" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.t("contract.texts.phraseTwoTerm")
                                  )
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "general" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.t("contract.texts.phraseFourTerm")
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _c("table", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$i18n.t(
                                  "contract.texts.coParticipationAccident"
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.$i18n.t(
                                  "contract.texts.coParticipationAccidentPrice"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$i18n.t(
                                  "contract.texts.coParticipationTheft"
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.$i18n.t(
                                  "contract.texts.coParticipationTheftPrice"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$i18n.t(
                                  "contract.texts.coParticipationTheftInOtherCases"
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.$i18n.t(
                                  "contract.texts.coParticipationTheftInOtherCasesPrice"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$i18n.t(
                                    "contract.texts.phraseCoverageLimit"
                                  )
                                ) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$i18n.t(
                                    "contract.texts.phraseCoverageLimitWarning"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.$i18n.t("contract.texts.coverageLimitPrice")
                              )
                            ),
                          ]),
                        ]),
                      ]),
                ]),
              ]),
            ]),
          ]),
          _c("table", { staticClass: "legal" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "general" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$i18n.t("contract.words.requests")) + " "
                  ),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", {
                  staticClass: "garage",
                  domProps: {
                    innerHTML: _vm._s(
                      `${_vm.$i18n.t(
                        "contract.texts.requestPhraseOne"
                      )} &nbsp;&nbsp; [&nbsp;&nbsp;&nbsp;]`
                    ),
                  },
                }),
              ]),
              _c("tr", [
                _c("td", {
                  staticClass: "garage",
                  domProps: {
                    innerHTML: _vm._s(
                      `${_vm.$i18n.t(
                        "contract.texts.requestPhraseTwo"
                      )} &nbsp;&nbsp; [&nbsp;&nbsp;&nbsp;]`
                    ),
                  },
                }),
              ]),
              _c("tr", [
                _c("td", {
                  staticClass: "garage",
                  domProps: {
                    innerHTML: _vm._s(
                      `${_vm.$i18n.t(
                        "contract.texts.requestPhraseThree"
                      )} &nbsp;&nbsp; [&nbsp;&nbsp;&nbsp;]`
                    ),
                  },
                }),
              ]),
              _vm.$i18n.i18next.exists("contract.texts.requestPhraseFour")
                ? _c("tr", [
                    _c("td", {
                      staticClass: "garage",
                      domProps: {
                        innerHTML: _vm._s(
                          `${_vm.$i18n.t(
                            "contract.texts.requestPhraseFour"
                          )} &nbsp;&nbsp; [&nbsp;&nbsp;&nbsp;]`
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
              _vm.$i18n.i18next.exists("contract.texts.requestPhraseFive")
                ? _c("tr", [
                    _c("td", {
                      staticClass: "garage",
                      domProps: {
                        innerHTML: _vm._s(
                          `${_vm.$i18n.t(
                            "contract.texts.requestPhraseFive"
                          )} &nbsp;&nbsp; [&nbsp;&nbsp;&nbsp;]`
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "signature" }, [
            _c("table", [
              _c("tr", [
                _c("td", { staticClass: "area" }, [
                  _c("div", { staticClass: "box" }),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$i18n.t("contract.words.signature"))),
                  ]),
                  _c("br"),
                  _c("span", [_vm._v(_vm._s(_vm.booking.driver.name))]),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$i18n.t("contract.words.document")) +
                      " " +
                      _vm._s(_vm.booking.driver.cpf) +
                      " "
                  ),
                ]),
                _c("td", { staticClass: "spacer" }, [_vm._v("     ")]),
                _c("td", { staticClass: "area" }, [
                  _c("div", { staticClass: "box" }),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$i18n.t("contract.texts.koviStaff"))),
                  ]),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.koviStaff) + " "),
                ]),
              ]),
            ]),
          ]),
        ])
      : _c(
          "div",
          { staticClass: "animated fadeIn" },
          [_c("content-loading")],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }